import React from 'react';
import SimulatorPage from './components/QuantumSimulator';

function App() {
  return (
    <SimulatorPage />
  );
}

export default App;
